import React, { useContext, useEffect } from 'react';
import UserContext  from '../../UserContext';

function ProtectedRoute({ children }) {
    const { user, login } = useContext(UserContext);

    useEffect(() => {
        if (!user) {
            login().catch((err) => console.log(err));
        }
    }, [user, login]);

    return user ? children : null; // or a Spinner while loading the user
}

export default ProtectedRoute;
