import {useEffect} from 'react';
import {useNavigate} from "react-router-dom";

import axios from 'axios';

export const baseUrlAPI = process.env.REACT_APP_API_URL || "invalid";
export const authServerUrl = process.env.REACT_APP_AUTH_SERVER_URL || "invalid";

function CallbackComponent() {
    const navigate = useNavigate();


    useEffect(() => {

        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        const state = urlParams.get('state');
        const encodedVerifier = new URLSearchParams(state).get('verifier');
        const codeVerifier = decodeURIComponent(encodedVerifier);

        const body = new URLSearchParams();


        body.append('grant_type', 'authorization_code');
        body.append('client_id', 'front-client');
        body.append('code', code);
        body.append('redirect_uri', baseUrlAPI + '/callback');
        body.append('code_verifier', codeVerifier);

        axios.post(authServerUrl + '/oauth2/token', body.toString(), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(response => {
            const {access_token, expires_in, scope, token_type} = response.data;

            window.localStorage.setItem('auth_token', access_token);
            window.localStorage.setItem('expires_in', expires_in);
            window.localStorage.setItem('scope', scope);
            window.localStorage.setItem('token_type', token_type);
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            navigate("/");
        });
    }, []);

    return <div>Processing sign-in...</div>;
}

export default CallbackComponent;
