import pkceService from './PKCEGeneratorService';


export const baseUrl = process.env.REACT_APP_API_URL || "invalid";
export const authServerUrl = process.env.REACT_APP_AUTH_SERVER_URL || "invalid";

export async function login() {
    await getAuthorizationCode();
}


export async function getAuthorizationCode() {
    // generate challenge first
    await pkceService.generateChallenge();

    // const endpoint = 'http://localhost:9000/oauth2/authorize';
    const endpoint = authServerUrl + '/oauth2/authorize';
    const clientId = 'front-client';
    // const redirectUri = 'http://127.0.0.1:3000/callback';
    const redirectUri = baseUrl + '/callback';
    const responseType = 'code';
    const codeChallengeMethod = 'S256';
    const codeVerifier = encodeURIComponent(pkceService.getCodeVerifier());  // Get verifier
    const codeChallenge = pkceService.getCodeChallenge();
    const scope = 'read';
    //THis is not secure, but I had to do this because I was not able to implement PCKE with ocid-client lib. Hopefully to implement in the future...
    const state = `verifier=${codeVerifier}`;  // Include encoded verifier in state

    const requestUrl = `${endpoint}?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=${responseType}&code_challenge=${codeChallenge}&code_challenge_method=${codeChallengeMethod}&scope=${scope}&state=${state}`;

    console.log("Redirecting to get authorization code request...");
    window.location.href = requestUrl;
}


export async function processSignInCallback() {
    console.log("Finished")
}

export async function getUser() {
    return window.localStorage.getItem('auth_token');//Add the expires in check

}

export function isAuthenticated() {
    return getUser().then((user) => !!user);
}