import {useState, useEffect, useContext} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import UserContext from './UserContext';
import {getUser, processSignInCallback, login} from './components/auth/authService';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Chat from './components/Chat';
import CallbackComponent from './components/auth/CallbackComponent';
import './App.css';
import GeneratedStory from "./components/story/GeneratedStory";


function App() {

    const [user, setUser] = useState(null);

    useEffect(() => {
        getUser().then(user => {
            if (user) {
                setUser(user);
            }
        });
    }, []);

    const handleLogin = () => {
        login()
    };

    const handleCallback = () => {
        processSignInCallback();
    };

    return (
        <UserContext.Provider value={{user, login: handleLogin, processSignInCallback: handleCallback}}>
            <Router>
                <Routes>
                    <Route path="/" element={<ProtectedRoute><Chat/></ProtectedRoute>}/>
                    <Route path="/generatedStory" element={<GeneratedStory />} />
                    <Route path="/callback" element={<CallbackComponent/>}/>
                </Routes>
            </Router>
        </UserContext.Provider>
    );
}

export default App;
