// GeneratedStory.js
import React, {useEffect, useState} from 'react';
import apiClient from '../../components/apiClient';
export const resourceUrl = process.env.REACT_APP_RESOURCE_URL || "invalid";

function GeneratedStory() {
    const [generatedStory, setGeneratedStory] = useState([]);
    //const baseUrl = "http://127.0.0.1:8090";
    const baseUrl = resourceUrl;


    useEffect(() => {
        fetchGeneratedStory();
    }, []);

    const fetchGeneratedStory = async () => {
        try {
            const res = await apiClient.get(baseUrl + '/resource-server/api/audios');
            console.log(res.data)
            setGeneratedStory(res.data);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <table className="storyTable">
            <thead>
            <tr>
                {/* Assuming that generatedStory is an object. Adjust these as needed. */}
                <th>Text</th>
            </tr>
            </thead>
            <tbody>
            {generatedStory.map((story, index) => (
                <React.Fragment key={index}>
                    <tr className="storyRow">
                        <td>
                            <h7>Voice = {story.voice}</h7>
                            <br/>
                            <a href={story.voiceUrl}
                               className="voice-url-link">Play</a>
                            <p>{story.text}</p>
                        </td>
                    </tr>
                    {story.storyImageSummaries.map((summary, sIndex) => (
                        <React.Fragment key={`${index}-${sIndex}`}>
                            <tr>
                                <td><h3>{summary.text}</h3></td>
                            </tr>
                            <tr>
                                <td>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        {summary.images.map((image, imgIndex) => (
                                            <a key={imgIndex} href={image.url} target="_blank"
                                               rel="noopener noreferrer">
                                                <img src={image.url} alt={`Story Image ${imgIndex + 1}`}
                                                     style={{width: '400px'}}/>
                                            </a>
                                        ))}
                                    </div>
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </React.Fragment>
            ))}

            </tbody>
        </table>
    );
}

export default GeneratedStory;
