import React from 'react';

const ConversationListItem = ({ conversation, onClick }) => {
    return (
        <div className="conversation-list-item" onClick={() => onClick(conversation)}>
            <div className="conversation-id">{conversation.conversationId} -  </div>
            <div className="model-id">{conversation.title}</div>
        </div>
    );
};

export default ConversationListItem;
