class PKCEGeneratorService {
    async generateChallenge() {
        const array = new Uint32Array(28);
        window.crypto.getRandomValues(array);
        const verifier = Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');

        console.log("Generated verifier: ", verifier);
        this.setCodeVerifier(verifier);

        const challengeStr = await this.generateCodeChallenge(verifier);

        this.setCodeChallenge(challengeStr);

        return challengeStr;
    }

    async generateCodeChallenge(codeVerifier) {
        const encoder = new TextEncoder();
        const data = encoder.encode(codeVerifier);

        const hashed = await window.crypto.subtle.digest('SHA-256', data);
        return this.base64urlencode(new Uint8Array(hashed));
    }

    base64urlencode(array) {
        return btoa(String.fromCharCode(...array))
            .replace('+', '-')
            .replace('/', '_')
            .replace(/=+$/, '');
    }

    getCodeVerifier() {
       return window.localStorage.getItem('verifier');
    }

    getCodeChallenge() {
        return window.localStorage.getItem('challenge');
    }

    setCodeChallenge(challenge) {
        window.localStorage.setItem('challenge', challenge);
    }
    setCodeVerifier(verifier) {
        window.localStorage.setItem('verifier', verifier);
    }

    removeCodeChallenge() {
        window.localStorage.removeItem('challenge');
    }

    removeCodeVerifier() {
        window.localStorage.removeItem('verifier');
    }

}

const pkceService = new PKCEGeneratorService();
export default pkceService;