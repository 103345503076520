import React, {useEffect, useRef, useState} from 'react';
import ChatMessage from './ChatMessage';
import ConversationListItem from './ConversationListItem';
import apiClient from './apiClient';
import {useNavigate} from "react-router-dom";
import VoiceSelectionModal from '../modal/VoiceSelectionModal';

export const baseUrlAPI = process.env.REACT_APP_API_URL || "invalid";
export const authServerUrl = process.env.REACT_APP_AUTH_SERVER_URL || "invalid";
export const resourceUrl = process.env.REACT_APP_RESOURCE_URL || "invalid";

const Chat = () => {
    const navigate = useNavigate();
    // const baseUrl = "http://127.0.0.1:8090";
    const baseUrl = resourceUrl;

    const [voices, setVoices] = useState([]);
    const [isVoiceSelectionOpen, setVoiceSelectionOpen] = useState(false);
    const [url, setUrl] = useState(null);
    const [generatedStory, setGeneratedStory] = useState([]);

    const messagesEndRef = useRef(null);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [conversationId, setConversationId] = useState(null);
    const [models, setModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState('');
    const [conversations, setConversations] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchModels();
        fetchConversations();
        fetchGeneratedStory();
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const fetchConversations = async () => {
        try {
            const response = await apiClient.get(baseUrl + '/resource-server/api/conversations');
            setConversations(response.data);
        } catch (error) {
            console.error('Error fetching conversations:', error);
        }
    };

    const fetchGeneratedStory = async () => {
        try {
            const res = await apiClient.get(baseUrl + '/resource-server/api/audios');
            console.log(res.data)
            setGeneratedStory(res.data);
        } catch (err) {
            console.error(err);
        }
    };

    const handleGenerateStory = async () => {
        // Copy the last message from the conversation
        const lastMessage = messages[messages.length - 1];

        // Check if the last message is from the bot
        if (!lastMessage.isUser) {
            try {
                // Get voices list
                const response = await apiClient.get(baseUrl +'/resource-server/api/voices');
                setVoices(response.data);

                // Open the voice selection modal
                setVoiceSelectionOpen(true);
            } catch (error) {
                console.error('Error fetching voices:', error);
            }
        }
    };

    const handleVoiceSelected = async (voice) => {
        // Close the voice selection modal
        setVoiceSelectionOpen(false);

        const payload = {
            voice,
            text: messages[messages.length - 1].text,
        };

        // Generate the audio
        try {
            const response = await apiClient.post(baseUrl +'/resource-server/api/audios/generate', payload);
            const uuid = response.data.uuid;
            console.log('uuid', uuid);
        } catch (error) {
            console.error('Error generating audio:', error);
        }
    };
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
    };
    const selectConversation = (conversation) => {
        setMessages(conversation.messages.map((message) => ({
            text: message.content,
            isUser: message.role === 'user',
        })));
        setConversationId(conversation.conversationId);
        setSelectedModel(conversation.modelId);
    };

    const fetchModels = async () => {
        try {
            const response = await apiClient.get(baseUrl +'/resource-server/api/models');
            setModels(response.data);
            setSelectedModel(response.data[0]?.version);
        } catch (error) {
            console.error('Error fetching models:', error);
        }
    };
    const sendMessage = async (event) => {
        event.preventDefault();
        if (input.trim() === '') return;

        setLoading(true);

        const payload = {
            conversationId: conversationId,
            message: input,
            modelId: selectedModel
        };

        // Add user message to the chat
        setMessages([...messages, {text: input, isUser: true}]);
        // Clear the input field
        setInput('');
        // Fetch response from your backend
        try {
            const response = await apiClient.post(baseUrl +'/resource-server/api/messages', payload);
            setConversationId(response.data.conversationId);
            console.log(response);
            // Add the Chat GPT response to the chat
            setMessages([...messages, {text: payload.message, isUser: true}, {
                text: response.data.message,
                isUser: false
            }]);
        } catch (error) {
            console.error('Error fetching response:', error);
        } finally {
            setLoading(false);
        }

    };

    const clearConversation = () => {
        setMessages([]);
        setConversationId(null);
    };

    const handleLogout = () => {
        navigate("/logout");
    };

    const handleTitleClick = () => {
        navigate("/generatedStory");
    }


    return (
        <div className="app-container">
            <div className="sidebar">
                <p className="conversation-title">Conversations</p>
                <div className="message-divider"></div>
                <div className="conversation-list">
                    {conversations.map((conversation) => (
                        <ConversationListItem
                            key={conversation.conversationId}
                            conversation={conversation}
                            onClick={selectConversation}
                        />
                    ))}
                    <button onClick={handleLogout} className="logout-button">Logout</button>
                </div>
            </div>
            <div className="chat-container">
                <div className="model-selection">
                    <label htmlFor="model-select">Select a model:</label>
                    <select
                        id="model-select"
                        value={selectedModel}
                        onChange={(e) => {
                            setSelectedModel(e.target.value);
                            clearConversation();
                        }}
                    >
                        {models.map((model) => (
                            <option key={model.version} value={model.version}>
                                {model.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="chat-messages">
                    {messages.map((message, index) => (
                        <>
                            <ChatMessage key={index} message={message.text} isUser={message.isUser}/>
                            {index < messages.length - 1 && <div className="message-divider"></div>}
                        </>
                    ))}
                    <div ref={messagesEndRef}></div>
                </div>
                <form onSubmit={sendMessage} className="chat-form">
                    <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder="Type your message..."
                    />
                    <button type="submit" disabled={loading} className="send-button">
                        {loading ? '...' : 'Send'}
                    </button>
                    <VoiceSelectionModal
                        voices={voices}
                        isOpen={isVoiceSelectionOpen}
                        onRequestClose={() => setVoiceSelectionOpen(false)}
                        onSelectVoice={handleVoiceSelected}
                    />
                    <button className="new-button" type="button" onClick={clearConversation}> + New Chat</button>

                    <button type="button" onClick={handleGenerateStory}>
                        Convert to Story
                    </button>
                </form>
                {loading && (
                    <div className="loader">
                        <h1>Loading...</h1>
                        {/* Add a loader element or an image here */}
                    </div>
                )}
            </div>
            <div className="sidebar right-sidebar scrollable">
                <p className="conversation-title" onClick={handleTitleClick} >Stories</p>
                <div className="message-divider"></div>
                {generatedStory.slice().reverse().map((storyVoice, index) => (
                    <div key={index}>

                        <h4>{index + 1} - <a href={storyVoice.video}
                                             className="voice-url-link">Play</a> ( {storyVoice.voice}  )
                            {storyVoice.text}</h4>
                        {storyVoice.storyImageSummaries && storyVoice.storyImageSummaries.map((storyImageSummary, storyImageSummaryIndex) => {
                            // Check if the images array is not empty
                            if (storyImageSummary.images && storyImageSummary.images.length > 0) {
                                const imgUrl = storyImageSummary.images[0].url;
                                return (
                                    <a key={storyImageSummaryIndex} href={imgUrl} target="_blank" rel="noopener noreferrer">
                                        <img src={imgUrl} alt={`Story Image ${storyImageSummaryIndex + 1}`} style={{width: '100px'}} />
                                    </a>
                                );
                            }
                            // If the images array is empty, do not return anything
                            return null;
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Chat;
