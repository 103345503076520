import axios from 'axios';
import { getUser } from './auth/authService';

export const resourceUrl = process.env.REACT_APP_RESOURCE_URL || "invalid";

const apiClient = axios.create({
    baseURL: resourceUrl, // Replace with your API base URL
    //baseURL: 'http://127.0.0.1:8090', // Replace with your API base URL
});
const auth_token = window.localStorage.getItem('auth_token');
apiClient.interceptors.request.use(async (config) => {
    const user = await getUser();
    if (user) {
        config.headers.Authorization = `Bearer ${auth_token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Clear token and perform logout
            window.localStorage.removeItem('auth_token');
            window.location.href = '/';
        }
        return Promise.reject(error);
    }
);

export default apiClient;