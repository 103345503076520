import Modal from 'react-modal';

// Style for the modal
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        maxWidth: '90vw',
        overflowY: 'auto',
        padding: '20px',
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
};
function VoiceSelectionModal({ voices, isOpen, onRequestClose, onSelectVoice }) {
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
            <h2>Select a voice</h2>
            {voices.map((voice) => (
                <div key={voice.id} onClick={() => onSelectVoice(voice.id)}>
                    {voice.name}
                </div>
            ))}
        </Modal>
    );
}

export default VoiceSelectionModal;
