import React from 'react';

const ChatMessage = ({ message, isUser }) => {
    return (
        <div className={`message-wrapper ${isUser ? "user-message-wrapper" : "bot-message-wrapper"}`}>
            <div className={`message ${isUser ? "user-message" : "bot-message"}`}>
                {message}
            </div>
        </div>
    );
};

export default ChatMessage;